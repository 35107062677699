import meta from "../../../src/pages/_meta.ts";
import examples_meta from "../../../src/pages/examples/_meta.ts";
export const pageMap = [{
  data: meta
}, {
  name: "examples",
  route: "/examples",
  children: [{
    data: examples_meta
  }, {
    name: "animation",
    route: "/examples/animation",
    frontMatter: {
      "sidebarTitle": "Animation"
    }
  }, {
    name: "context",
    route: "/examples/context",
    frontMatter: {
      "sidebarTitle": "Context"
    }
  }, {
    name: "counters",
    route: "/examples/counters",
    frontMatter: {
      "sidebarTitle": "Counters"
    }
  }, {
    name: "errors",
    route: "/examples/errors",
    frontMatter: {
      "sidebarTitle": "Errors"
    }
  }, {
    name: "event-handlers",
    route: "/examples/event-handlers",
    frontMatter: {
      "sidebarTitle": "Event Handlers"
    }
  }, {
    name: "fetch",
    route: "/examples/fetch",
    frontMatter: {
      "sidebarTitle": "Fetch"
    }
  }, {
    name: "form-data",
    route: "/examples/form-data",
    frontMatter: {
      "sidebarTitle": "Form Data"
    }
  }, {
    name: "search",
    route: "/examples/search",
    frontMatter: {
      "sidebarTitle": "Search"
    }
  }, {
    name: "simple",
    route: "/examples/simple",
    frontMatter: {
      "sidebarTitle": "Simple"
    }
  }, {
    name: "sync",
    route: "/examples/sync",
    frontMatter: {
      "sidebarTitle": "Sync"
    }
  }, {
    name: "todo-app",
    route: "/examples/todo-app",
    frontMatter: {
      "sidebarTitle": "Todo App"
    }
  }]
}, {
  name: "guide",
  route: "/guide",
  frontMatter: {
    "sidebarTitle": "Guide"
  }
}, {
  name: "index",
  route: "/",
  frontMatter: {
    "sidebarTitle": "Index"
  }
}, {
  name: "reference",
  route: "/reference",
  frontMatter: {
    "sidebarTitle": "Reference"
  }
}];